import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { InventoryClient } from "../../../clients/InventoryClient"
import { Vehicle } from "../../../global"
import Lottie from "react-lottie-player"
import lottieJson from "../../../animations/14717-sedan-car-animation.json"
import InventoryResultCard from "../Inventory/InventoryResultCard"
import AliceCarousel from "react-alice-carousel"
import Icon from "../../atoms/Icon"
import { css } from "@emotion/react"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import DealerDefaultMessage from "./DefaultSection"

const DealerRecentlyViewed: React.FC = () => {
  const { dealer } = useContext(DealerDetailsPageContext)
  const [isLoading, setVehiclesLoading] = useState<boolean>(false)
  const [vehicleData, setVehicleData] = useState<Vehicle[]>([])
  const { _ } = useContext(LanguageContext)

  useEffect(() => {
    const updateVehicleData = async () => {
      // recently viewed vins ordered from least recently viewed to most recently viewed
      const recentlyViewed = LocalStorageClient.read("recentlyViewed")

      if (
        !recentlyViewed ||
        (Array.isArray(recentlyViewed) && recentlyViewed.length === 0)
      ) {
        setVehiclesLoading(false)
        return
      }

      // 20 most recently viewed vins
      const mostRecentlyViewed: string[] = Array.isArray(recentlyViewed)
        ? [...recentlyViewed].reverse()?.splice(0, 20)
        : [recentlyViewed]
      const vehicleData = await InventoryClient.get({
        vin: mostRecentlyViewed.join(","),
        include: ["accessories"],
        limit: 20,
      })

      // Filter vehicleData based on dealer.DealerCode
      const filteredVehicleData = vehicleData.vehicles.filter(
        vehicle => vehicle.dealer === dealer.DealerCode,
      )

      const sortedVehicleData = filteredVehicleData.sort(
        (a: Vehicle, b: Vehicle) => {
          return (
            mostRecentlyViewed.indexOf(a.vin) -
            mostRecentlyViewed.indexOf(b.vin)
          )
        },
      )
      setVehicleData(sortedVehicleData)
      setVehiclesLoading(false)

      // Update local storage to only store 20 most recently viewed vehicles, ordered from least recently viewed to most recently viewed
      if (Array.isArray(recentlyViewed) && recentlyViewed.length > 20) {
        const recentlyViewedVins = recentlyViewed.slice(-20)
        LocalStorageClient.delete("recentlyViewed")
        LocalStorageClient.write("recentlyViewed", recentlyViewedVins)
      }
    }

    setVehiclesLoading(true)
    updateVehicleData()
  }, [])

  useEffect(() => {
    const recentlyViewedItems = vehicleData.map(
      (vehicle: Vehicle, index: number) => (
        <div css={[tw`min-w-[380px]`, tw`lg:(max-w-[420px])`]}>
          <div
            css={[
              tw`mx-auto flex flex-nowrap`,
              tw`md:(mx-12)`,
              tw`lg:(mx-4)`,
              tw`pb-[516px] mb-[-500px]`,
            ]}
          >
            <InventoryResultCard
              css={[tw`bg-white`]}
              vehicle={vehicle}
              index={index}
              location={"recent"}
              removeRecentlyViewedVehicle={vehicle => {
                setVehicleData(vehicleData.filter(v => v.vin !== vehicle.vin))
              }}
            />
          </div>
        </div>
      ),
    )
    setRecentlyViewedItems(recentlyViewedItems) // Set the recentlyViewedItems to state
  }, [vehicleData])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const renderPrevButtonBottom = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute left-[30%] -bottom-6 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          tw`md:(-left-6 top-[45%])`,
          tw`lg:(-left-14)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "left arrow",
          })
        }
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButtonBottom = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute right-[30%] -bottom-6 -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          tw`md:(-right-6 top-[45%])`,
          tw`lg:(-right-14)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
        onClick={() =>
          trackTealEvent({
            tealium_event: "carousel_click",
            carousel_action: "right arrow",
          })
        }
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const redUnderlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `
  const responsiveInventory = {
    0: { items: 1 },
    1200: { items: 2 },
  }

  const [recentlyViewedItems, setRecentlyViewedItems] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <>
      <div
        css={[
          tw`w-full text-3xl font-light mt-4 px-4 text-center`,
          tw`lg:(text-3xl text-left mt-8 ml-8 max-w-[300px] min-w-[300px])`,
          tw`2xl:(ml-0)`,
          "letter-spacing: 6px;",
        ]}
      >
        {_("Recently Viewed from")} {dealer.Name}
      </div>
      <section
        aria-label="Recently Viewed Vehicles Section"
        css={[
          tw`text-center w-full mx-auto pt-8`,
          tw`sm:(w-[600px])`,
          tw`xl:(w-[800px])`,
        ]}
      >
        {isLoading ? (
          <div
            css={[tw`z-10 bg-opacity-70 flex items-center justify-center h-96`]}
          >
            <div>
              <span
                css={[
                  tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                ]}
              >
                {_("Hang tight, we're fetching your recently viewed vehicles.")}
              </span>
              <div>
                <Lottie loop animationData={lottieJson} play />
              </div>
            </div>
          </div>
        ) : (
          <div
            css={[
              recentlyViewedItems?.length === 0
                ? tw`mb-[20px]`
                : tw`mb-5 mx-auto justify-center`,
            ]}
          >
            {recentlyViewedItems.length === 0 ? (
              // Render a default message when no recently viewed vehicles are available
              <div key="default-message" css={[tw`overflow-visible`]}>
                <DealerDefaultMessage />
              </div>
            ) : (
              <div
                css={[
                  tw`relative mx-0`,
                  tw`md:(mx-8)`,
                  tw`xl:(mx-auto max-w-[800px])`,
                  tw`2xl:(mx-auto max-w-5xl)`,
                ]}
              >
                <AliceCarousel
                  mouseTracking={false}
                  touchTracking={false}
                  items={recentlyViewedItems}
                  disableDotsControls
                  responsive={responsiveInventory}
                  paddingLeft={0}
                  paddingRight={0}
                  controlsStrategy="alternate"
                  renderPrevButton={renderPrevButtonBottom}
                  renderNextButton={renderNextButtonBottom}
                  activeIndex={activeIndex}
                  onSlideChanged={({ item }) => setActiveIndex(item)}
                />
              </div>
            )}
          </div>
        )}
      </section>
    </>
  )
}

export default DealerRecentlyViewed
